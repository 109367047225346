var ShowcaseBanner = (function () {
    function ShowcaseBanner() {
        this.showcaseBannerElement = document.querySelector('[data-showcase-banner]');
        this.animateClass = 'c-showcase-banner--animate';
        this.stopClass = 'c-showcase-banner--stop';
        this.basicClass = 'c-showcase-banner--basic';
        if (!this.showcaseBannerElement)
            return;
        if (this.isEnabled()) {
            this.setupShowcaseBanner(this.showcaseBannerElement);
        }
        else {
            this.showcaseBannerElement.classList.add(this.basicClass);
        }
    }
    ShowcaseBanner.prototype.setupShowcaseBanner = function (showcaseBannerElement) {
        var context = this;
        var list = showcaseBannerElement.querySelector('[data-showcase-banner-list]');
        var featuredVideo = showcaseBannerElement.querySelector('[data-showcase-banner-featured] [data-video]');
        var delayAutoplay = featuredVideo ? true : false;
        context.tnsInstance = tns({
            container: list,
            slideBy: 1,
            mouseDrag: true,
            controls: true,
            nav: false,
            items: 1,
            loop: true,
            rewind: true,
            preventScrollOnTouch: 'auto',
            swipeAngle: 30,
            mode: 'gallery',
            autoplay: true,
            autoplayButtonOutput: false,
            autoplayTimeout: 6000,
            controlsPosition: 'bottom'
        });
        if (delayAutoplay && !Helpers.isLegacyIE()) {
            context.stopBanner(context);
            var bannerHasStarted_1 = false;
            document.addEventListener('showCaseBannerVideoLoaded', function (e) {
                if (!bannerHasStarted_1) {
                    bannerHasStarted_1 = true;
                    context.startBanner(context);
                }
            });
        }
        context.animateBanner(context);
        context.tnsInstance.events.on('transitionStart', function () { context.animateBanner(context); });
        context.tnsInstance.events.on('touchStart', function () { context.stopBanner(context); });
        context.tnsInstance.events.on('dragStart', function () { context.stopBanner(context); });
    };
    ShowcaseBanner.prototype.animateBanner = function (context) {
        context.showcaseBannerElement.classList.remove(context.animateClass);
        setTimeout(function () { context.showcaseBannerElement.classList.add(context.animateClass); }, 100);
    };
    ShowcaseBanner.prototype.stopBanner = function (context) {
        context.tnsInstance.stop();
        context.showcaseBannerElement.classList.add(context.stopClass);
    };
    ShowcaseBanner.prototype.startBanner = function (context) {
        context.tnsInstance.start();
        context.showcaseBannerElement.classList.remove(context.stopClass);
        context.animateBanner(context);
    };
    ShowcaseBanner.prototype.isEnabled = function () {
        return window.matchMedia("(max-width: 1099px)").matches;
    };
    return ShowcaseBanner;
}());
new ShowcaseBanner();
